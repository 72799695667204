import headerLogo from '../img/headerLogo.png';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function QPFooter(params) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      md: 600,
      xs: 300,
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: {
      xs: 2,
      md: 3,
    },
  };

  return (
    <div className="quickPricerBody__container container message">
      <div className="message__header">
        <div className="message__header__logo">
          <a href="https://admortgage.com/" target="_blank" rel="noopener noreferrer">
            <img src={headerLogo} alt="logo" />
          </a>
        </div>
        <Button
          sx={{
            p: {
              xs: 0.2,
              md: 0.2,
            },
            color: '#263B5A',
          }}
          variant="text"
          endIcon={<InfoOutlinedIcon />}
          onClick={handleOpen}
        >
          What's new
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            Our Quick Pricer can now calculate Non-Qm, Prime Jumbo, Conventional and Government products! Here is how it works:
              <IconButton
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '5px',
                  right: '10px',
                  color: '#d32f2f',
                  fontWeight: 'bold',
                  p: 0,
                }}
                onClick={handleClose}
              >
                x
              </IconButton>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <ul>
                <li>Set borrower’s parameters in QP</li>
                <li>
                QP determines available products for set parameters and offers a product with the lowest rate
                </li>
                <li>
                In each parameter there is a range of allowable values applicable for different programs and products
                </li>
                <li>
                Selected values can affect limits in other parameters so that eligible programs can be offered
                </li>
                <li>
                If the selected value is unavailable, you will receive a hint with a parameter that needs to be adjusted
                </li>
                <li>
                Separately selectable states have specific limitations, provided with commentaries. In contrast, “All other states” include all eligible states without any restrictions, where A&D Mortgage operates. Please, check our rate sheets for detailed information about states and restrictions.
                </li>
                <li>
                  DTI parameter implemented
                </li>
              </ul>
              <i>*Although we have good artificial intelligence, the final rate may vary.</i>
            </Typography>
          </Box>
        </Modal>
      </div>
      A&D Mortgage Quick Pricer is intended for licensed mortgage and real estate professionals only
      and is not intended to be utilized by potential borrowers or by the general public. The
      information does not take into consideration borrower’s personal financial situation, verified
      credit score, income, existing debt, or other factors. This is not a commitment to lend. The
      non-binding quotes are not official Loan Estimates under Reg. Z. A&D Mortgage LLC is an equal
      opportunity lender. Rate, terms and programs subject to change without notice. Refinancing the 
      borrowers existing loan, may increase total finance charges over the life of the loan.
    </div>
  );
}
