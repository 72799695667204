import { ValuesState } from './redux/valuesReducer';
import { FilterModel } from './server/schema/api';
import { validateSlider } from './validators/slider-validator';

export const getSliderLimit = (limits: FilterModel[], inputId: number) => {
  return limits.find((e) => e.id === inputId)?.availableValues.find((e) => e.id === 999);
};

export const dropInvalidOptionalValues = (
  inputs: FilterModel[],
  limits: FilterModel[],
  values: ValuesState,
): ValuesState => {
  const validInputsIds = inputs.reduce((result, input) => {
    if (input.uiType === 'CheckBox') {
      const checkboxLimits = limits.find((e) => e.id === input.id)?.availableValues || [];
      const selectedValueId = values.find((e) => e.id === input.id)?.value;
      const selectedValue = checkboxLimits.find((e) => e.id === selectedValueId);
      const isValid = Boolean(selectedValue);
      const shouldDrop = !input.checkLimit && !isValid;
      return shouldDrop ? result : [...result, input.id];
    }
    if (input.uiType === 'Slider') {
      const limit = getSliderLimit(limits, input.id);
      const value = values.find((e) => e.id === input.id)?.sliderValue;
      const isValid = validateSlider(limit, value);
      const shouldDrop = !input.checkLimit && !isValid;
      return shouldDrop ? result : [...result, input.id];
    }

    if (input.uiType === 'SelectList') {
      const selectLimits = limits.find((e) => e.id === input.id)?.availableValues || [];

      const options = input.availableValues.map((value) => {
        const limit = selectLimits.find((e) => e.id === value.id);
        return {
          ...value,
          isDisabled: limit ? limit.isDisabled : true,
        };
      });

      const selectedValueId = values.find((e) => e.id === input.id)?.value;
      const selectedValue = options.find((e) => e.id === selectedValueId);
      const isValid = !selectedValue?.isDisabled;
      const shouldDrop = !input.checkLimit && !isValid;
      return shouldDrop ? result : [...result, input.id];
    }

    return [...result, input.id];
  }, [] as number[]);
  return values.filter((e) => validInputsIds.includes(e.id));
};
