import { Checkbox } from '@mui/material';
import React from 'react';
import { BaseFilterModel } from '../../server/schema/api';

type Props = {
  order: number;
  selectedValue?: BaseFilterModel;
  name: string;
  isValid: boolean;
  values?: BaseFilterModel[];
  onChange?: (value: BaseFilterModel) => void;
};

export const QPSwitch: React.FC<Props> = ({
  name,
  order,
  values,
  selectedValue,
  isValid,
  onChange,
}) => {
  const onInputChange = () => {
    const wasChecked = selectedValue?.value === '1';
    const nextValue = values?.find((e) => (wasChecked ? e.value === '0' : e.value === '1'));
    if (nextValue && !nextValue.isDisabled) {
      onChange?.(nextValue);
    }
  };
  const availableCount = values?.filter((e) => !e.isDisabled).length || 0;
  return (
    <div
      className="switch"
      style={{ order, ...(isValid ? {} : { backgroundColor: 'rgba(255,0,0,0.2)' }) }}
    >
        <Checkbox
          style={{color: availableCount === 1 && isValid ? 'gray' : '#ca363a', padding:0}}
          id={name}
          checked={selectedValue?.value === '1'}
          disabled={availableCount  === 0}
          onChange={onInputChange}
        ></Checkbox>
      <label htmlFor={name}>{name}</label>
    </div>
  );
};
