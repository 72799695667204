import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QPAutocomplete } from '../components/inputs/QPAutocomplete';
import { QPMuiSelect } from '../components/inputs/QPMuiSelect';
import { updateInputs, updateNoLimitInputs } from '../redux/actions';
import { limitsSelector, valuesSelector } from '../redux/selectors';
import { BaseFilterModel, FilterModel } from '../server/schema/api';
export const useSelect = () => {
  const dispatch = useDispatch();
  const { limits } = useSelector(limitsSelector);
  const values = useSelector(valuesSelector);
  return useCallback(
    (select: FilterModel) => {  
      const selectLimits = limits.find((e) => e.id === select.id)?.availableValues || [];

      const options = select.availableValues.map((value) => {
        const limit = selectLimits.find((e) => e.id === value.id);
        return {
          ...value,
          isDisabled: limit ? limit.isDisabled : true,
        };
      });
      const selectedValueId = values.find((e) => e.id === select.id)?.value;
      const selectedValue = options.find((e) => e.id === selectedValueId);
      const isValid = !selectedValue?.isDisabled;
      const isVisible =
        select.checkLimit || (!select.checkLimit && options.some((e) => !e.isDisabled));
      const handleChange = (value: BaseFilterModel) => {
        const isValid = !value.isDisabled;
        dispatch({ type: 'SET_IS_VALID', payload: { [select.id]: isValid } });
        if (select.checkLimit)
        dispatch(updateInputs({ id: select.id, value: value.id }));
        else {dispatch(updateNoLimitInputs({ id: select.id, value: value.id }));}
      };
      return isVisible ? (
        options.length > 11 ? <QPAutocomplete
        values={options}
        id={select.id}
        isValid={isValid}
        order={select.order}
        key={select.id}
        onChange={handleChange}
        name={select.name}
        selectedValue={selectedValue}
      />:         <QPMuiSelect
      selectedValue={selectedValue}
      name={select.name}
      key={select.id}
      order={select.order}
      id={select.id}
      isValid={isValid}
      values={options}
      onChange={handleChange}
    />
      ) : null;
    },
    [dispatch, limits, values],
  );
};
