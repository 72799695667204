export const validateSlider = (limit: any, value: number | undefined): boolean => {
  if (!limit) return true;
  if(!value) return false;
  if (limit.from) {
    return value >= limit.from;
  }

  if (limit.to) {
    return value <= limit.to;
  }
  return true;
};
