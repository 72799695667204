import React, { useEffect, useState } from 'react';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useSelector } from 'react-redux';
import {
  inputsSelector,
  limitsStateSelector,
  loansSelector,
  loansStateSelector,
  loansSuccessSelector,
  programSelector,
  validationSelector,
  valuesSelector,
} from '../redux/selectors';
import { useVariantsRef } from '../hooks/use-variants-ref';
import { Box, CircularProgress, Modal } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

export default function QuickPricerResponse(props) {
  const loan = useSelector(loansSelector);

  const [open, setOpen] = React.useState(false);
  const [modalParams, setModalParams] = React.useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [variant, setVariant] = useState<any>(null);
  const [variantIdx, setVariantIdx] = useState(0);

  const loanSuccess = useSelector(loansSuccessSelector);
  const program = useSelector(programSelector);
  const loanState = useSelector(loansStateSelector);
  const limitsState = useSelector(limitsStateSelector);
  const validation = useSelector(validationSelector);
  const inputs = useSelector(inputsSelector);
  const values = useSelector(valuesSelector);
  const ficoInput = inputs.find((i) => i.name === 'FICO');
  const ltvInput = inputs.find((i) => i.name === 'LTV');
  const fico = values.find((i) => i.id === ficoInput?.id);
  const ficoValue = fico?.value
    ? ficoInput?.availableValues.find((e) => e.id === fico.value)?.value
    : fico?.sliderValue ?? '';
  const ltv = values.find((i) => i.id === ltvInput?.id);
  const ltvValue = ltv?.value
    ? ltvInput?.availableValues.find((e) => e.id === ltv.value)?.value
    : ltv?.sliderValue ?? '';

  const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

  useEffect(() => {
    const idx = loan?.variants.findIndex((e) => e.id === loan.currentVariant);
    setVariantIdx(idx ?? 0);
  }, [loan]);

  useEffect(() => setVariant(loan?.variants[variantIdx]), [loan, variantIdx]);

  const handleIncrement = () => {
    if (variantIdx !== (loan?.variants || []).length - 1) {
      setVariantIdx(variantIdx + 1);
    }
  };
  const handleDecrement = () => {
    if (variantIdx !== 0) {
      setVariantIdx(variantIdx - 1);
    }
  };

  const createModalParams = (values, inputs) => {
    const modalParams = values.map((value) => ({
      // id: value.id,
      value: !!value.value ? value.value : value.sliderValue,
      name: inputs.find((i) => i.id === value.id).name,
      textValue: !!value.value
        ? inputs.find((i) => i.id === value.id).availableValues.find((i) => i.id === value.value)
            ?.value
        : value.sliderValue,
    }));

    return modalParams.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.name]: { value: item.value, textValue: item.textValue } }),
      {},
    );
  };

  useEffect(
    () =>
      setModalParams(
        JSON.stringify({
          ...{
            discount: { value: variant?.discount },
            monthlyPayment: { value: variant?.monthlyPayment },
            rate: { value: variant?.rate },
          },
          ...{
            program: {
              value: program.availableValues[0].id,
              textValue: program.availableValues[0].value,
            },
          },
          ...{
            finalProgram: { value: loan?.id, textValue: loan?.name },
            description: { value: loan?.description?.replace(htmlRegexG,"") },
          },
          ...createModalParams(values, inputs),
        }),
      ),
    [htmlRegexG, inputs, loan?.description, loan?.id, loan?.name, program.availableValues, values, variant?.discount, variant?.monthlyPayment, variant?.rate],
  );

  const discountPointsRef = useVariantsRef(handleIncrement, handleDecrement);
  const monthlyPaymentRef = useVariantsRef(handleIncrement, handleDecrement);
  const rateRef = useVariantsRef(handleIncrement, handleDecrement);

  if (!Object.values(validation).every((isValid) => isValid)) {
    const validationArray = Object.keys(validation).reduce((result, id) => {
      if (!validation[id]) {
        const name = inputs.find((e) => `${e.id}` === id)?.name;
        if (name) {
          return [...result, name];
        }
      }
      return result;
    }, [] as string[]);

    console.log('condition >>' ,validation);
    
    console.log('validationArray >>', validationArray);
    if (!!validationArray.length) {
      return (
        <div className="quickPricerBody__response">
          <h3>Values are out of limit</h3>
          <div className="quickPricerBody__programCard programCard">
            <div className="programCard__info">
              <ul>
                {validationArray.map((el) => {
                  return (
                    <li key={el}>
                      <h4>{el}</h4>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    }


  }

  if (limitsState !== 'DONE' || loanState !== 'DONE') {
    return (
      <div className="quickPricerBody__response">
        <div className="response-spinner-wrapper">
          <CircularProgress style={{ color: '#00a2ae' }} />
        </div>
      </div>
    );
  }

  if (!loanSuccess) {
    return (
      <div className="quickPricerBody__response">
        <h3>Could not find program for selected filters </h3>
      </div>
    );
  }

  const styleModal = {
    maxWidth: '100%',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    height: '850px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0.625,
  };

  // Create IE + others compatible event handler
  var eventMethod = (window.addEventListener as any) ? 'addEventListener' : 'attachEvent';
  var eventer = window[eventMethod];
  var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  // Listen to message from child window
  eventer(
    messageEvent,
    function (e) {
      // console.log('parent received message!:  ', e.data);
      if (e.data.close) {
        handleClose();
        //   console.log(
        //     'modalParams :>> ',
        //     JSON.stringify({
        //       ...{
        //         program: {
        //           value: program.availableValues[0].id,
        //           textValue: program.availableValues[0].value,
        //         },
        //       },
        //       ...{ finalProgram: { value: loan?.id, textValue: loan?.name } },
        //       ...modalParams,
        //     }),
        //   );
      }
    },
    false,
  );

  return (
    <div className="quickPricerBody__response">
      <h3>One loan program found</h3>
      <div className="quickPricerBody__programCard programCard">
        <div className="programCard__info">
          <h4>{loan?.name}</h4>
          <div className="programCard__ficoLtv">
            <span>
              {`FICO: ${ficoValue}`}
              <i className="infoIcon"></i>
              <div className="infoIcon__limit">Min FICO: {ficoInput?.from}</div>
            </span>
            <span>
              {`LTV: ${ltvValue}`}
              <i className="infoIcon"></i>
              <div className="infoIcon__limit">Max LTV: {ltvInput?.to}</div>
            </span>
          </div>
        </div>
        <div className="programCard__config">
          <div className="programCard__numericFields">
            <NumericTextBoxComponent
              ref={rateRef}
              format="###.###'%'"
              floatLabelType="Always"
              placeholder="Rate"
              id="rate-response"
              value={parseFloat(variant?.rate || '')}
            />
            <NumericTextBoxComponent
              ref={monthlyPaymentRef}
              format="'$'#,###.## "
              floatLabelType="Always"
              placeholder="Monthly payment"
              value={parseFloat(variant?.monthlyPayment || '')}
            />
            <NumericTextBoxComponent
              ref={discountPointsRef}
              format="###.###"
              floatLabelType="Always"
              value={parseFloat(variant?.discount || '')}
              placeholder={
                parseFloat(variant?.discount || '') >= 0 ? 'Discount points' : 'Lender Credit'
              }
            />
          </div>
          <div className="programCard__additionalInfo" dangerouslySetInnerHTML={{__html: loan?.description ?? ''}} />
          <div className="row">
            <button className="programCard__btn" onClick={handleOpen}>
              <ShareIcon className="programCard__btn__icon"/>
              Share
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        //   onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="quickPricerBody__modal"
      >
        <Box sx={styleModal} className="quickPricerBody__modal__box">
          <iframe
            title="modalStage"
            src={`https://admortgage.com/modal-qp/?params=${btoa(
              unescape(encodeURIComponent(modalParams)),
            )}`}
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
}
