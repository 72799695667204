import {  useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelect } from '../hooks/use-select';
import { fetchInputs, updatePrograms } from '../redux/actions';
import { headerInputsSelector, programSelector } from '../redux/selectors';
import { QPMuiProgramSelect } from './inputs/QPMuiProgramSelect';

const QPSelects = () => {
  const dispatch = useDispatch();
  const program = useSelector(programSelector);
  const inputs = useSelector(headerInputsSelector);


  const selectedProgram = useMemo(
    () =>
      !!program?.value
        ? program.availableValues?.find((e) => e.id === parseInt(program.value))
        : program.availableValues?.find((e) => e.id === program.defaultValue),
    [program],
  );

  const handleProgramChange = (programSelect) => {
    dispatch(updatePrograms({ ...program, value: programSelect.id }));
    dispatch(fetchInputs(programSelect.id));
  };
  const renderSelect = useSelect();

  return (
    <div className="quickPricer__selects">
      <div className="container">
        <QPMuiProgramSelect
          selectedValue={selectedProgram}
          key={program.id}
          order={program.order}
          name={program.name}
          id={program.id}
          isValid={true}
          values={program.availableValues}
          onChange={handleProgramChange}
        />
        {inputs.map(renderSelect)}
      </div>
    </div>
  );
};

export default QPSelects;
