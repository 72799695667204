import { Checkbox } from '@mui/material';
import {
  ColorRangeDataModel,
  NumericTextBoxComponent,
  SliderComponent,
} from '@syncfusion/ej2-react-inputs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BaseFilterModel } from '../../server/schema/api';

type Props = {
  id: number;
  order: number;
  min: number;
  max: number;
  step?: number;
  fullValue?: { value?: number; sliderValue?: number };
  label?: string;
  limitFrom?: number;
  limitTo?: number;
  isValid: boolean;
  specialValues?: BaseFilterModel[];
  onChange?: (number) => void;
  onSpecialClick?: (data: { type: 'check' | 'uncheck'; special: BaseFilterModel }) => void;
};

export const QuickPricerSlider: React.FC<Props> = ({
  max,
  min,
  step,
  id,
  order,
  label,
  limitFrom,
  limitTo,
  fullValue = {},
  isValid = true,
  onChange,
  onSpecialClick,
  specialValues = [],
}) => {
  const { value: specialValue, sliderValue } = fullValue;
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setMouseDown] = useState(false);
  const [numericValue, setSliderNumericValue] = useState(sliderValue);
  const [colorRanges, setColorRanges] = useState<ColorRangeDataModel[]>();
  const [limitValue, setLimitValue] = useState<number>();

  const showLimitValue = useMemo(
    () => limitValue !== min && limitValue !== max,
    [min, max, limitValue],
  );

  useEffect(() => {
    if (sliderValue !== undefined) {
      onInputChange({ value: sliderValue });
    }
  }, [sliderValue]);

  useEffect(() => {
    const red = '#ca363a';
    const green = '#00a2ae';
    const black70 = '#4d4d4d';
    const black40 = '#999999';
    const availableColor = !!specialValue ? black40 : green;
    const errorColor = !!specialValue ? black70 : red;
    const sliderLimitTick = document.getElementById(`slider${id}__limit-tick`);

    if (limitFrom) {
      setLimitValue(limitFrom);
      setColorRanges([
        { start: 0, end: limitFrom, color: errorColor },
        { start: limitFrom, end: max, color: availableColor },
      ]);

      const limitLength = ((limitFrom - min) * 100) / (max - min);
      sliderLimitTick && (sliderLimitTick.style.left = `${limitLength - 1.5}%`);
    }
    if (limitTo) {
      setLimitValue(limitTo);
      setColorRanges([
        { start: min, end: limitTo, color: availableColor },
        { start: limitTo, end: max, color: errorColor },
      ]);

      const limitLength = ((max - limitTo) * 100) / (max - min);
      sliderLimitTick && (sliderLimitTick.style.left = `${100 - limitLength - 1.5}%`);
    }
    if (!limitFrom && !limitTo) {
      setColorRanges([{ start: min, end: max, color: availableColor }]);
    }
  }, [limitFrom, limitTo, min, max, id, specialValue]);

  useEffect(() => {
    document.getElementById(`slider${id}-numeric-textbox`)!.parentElement!.style.background =
      isValid ? '#f1f2f3' : 'rgba(255,0,0,0.2)';
  }, [isValid, id]);

  function onInputChange(e) {
    if (isMouseDown) {
      return;
    }
    const value = parseFloat(e.value);
    const roundStep = step ?? 1;
    const roundedValue = Math.round(value / roundStep) * roundStep;
    setSliderNumericValue(roundedValue);
    if (!e.target) {
      onChange?.(roundedValue);
    }
  }

  useEffect(() => {
    const slider = sliderRef?.current;
    const handleMouseDown = () => setMouseDown(true);
    const handleMouseUp = () => setMouseDown(false);

    slider?.addEventListener('mousedown', handleMouseDown, { capture: true });
    slider?.addEventListener('mouseup', handleMouseUp, { capture: true });

    return () => {
      slider?.removeEventListener('mousedown', handleMouseDown, { capture: true });
      slider?.removeEventListener('mouseup', handleMouseUp, { capture: true });
    };
  }, []);

  function renderingTicksHandler(args) {
    const tickValue = args.value;
    const formatedText =
      tickValue / 1000000 >= 1
        ? parseFloat((tickValue / 1000000).toFixed(1)) + ' M'
        : tickValue / 100000 >= 1
        ? parseFloat((tickValue / 1000).toFixed(1)) + ' K'
        : tickValue;
    args.text = formatedText;
  }

  const formatedText =
    limitValue! / 1000000 >= 1
      ? parseFloat((limitValue! / 1000000).toFixed(1)) + ' M'
      : limitValue! / 100000 >= 1
      ? parseFloat((limitValue! / 1000).toFixed(1)) + ' K'
      : limitValue!;

  const renderSpecials = useCallback(
    () => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingLeft: '110px',
          position: 'absolute',
        }}
      >
        {specialValues
          .sort((a, b) => a.order - b.order)
          .map((special) => {
            const isChecked = specialValue === special.id;
            const isDisabled = special.isDisabled;
            const isValid = !(isChecked && isDisabled);
            return (
              <div
                key={special.id}
                style={{
                  marginBottom: '5px',
                  ...(isValid ? {} : { backgroundColor: 'rgba(255,0,0,0.2)' }),
                }}
              >
                <Checkbox
                  style={{
                    flexBasis: '50%',
                    color: special.isDisabled ? 'gray' : '#ca363a',
                    padding: '0px',
                    paddingRight: '5px',
                  }}
                  id={`${special.id}`}
                  checked={isChecked}
                  disabled={isDisabled && !isChecked}
                  onChange={() =>
                    onSpecialClick?.({ type: isChecked ? 'uncheck' : 'check', special })
                  }
                />
                <label style={{ color: isDisabled ? 'grey' : 'black' }} htmlFor={`${special.id}`}>
                  {special.value}
                </label>
              </div>
            );
          })}
      </div>
    ),
    [specialValues, specialValue, onSpecialClick],
  );
  return (
    <div
      className="wrap"
      style={window.innerWidth >= 1250 ? { order: order } : { order: 0 }}
      onMouseLeave={() => setMouseDown(false)}
    >
      <NumericTextBoxComponent
        style={!!specialValue ? { backgroundColor: '#f1f2f3' } : {}}
        min={min}
        max={max}
        step={step}
        showSpinButton={false}
        format="n"
        id={`slider${id}-numeric-textbox`}
        value={numericValue}
        disabled={!!specialValue}
        placeholder={label}
        onChange={onInputChange}
        cssClass="e-filled"
        floatLabelType="Auto"
      />
      <div className="sliderwrap" ref={sliderRef}>
        <SliderComponent
          id={`slider${id}`}
          readonly={!!specialValue}
          colorRange={colorRanges ? colorRanges : []}
          step={step}
          value={numericValue}
          min={min}
          max={max}
          change={(e) => setSliderNumericValue(e.value)}
          changed={(e) => onInputChange(e)}
          ticks={{
            placement: 'After',
            largeStep: max - min,
            showSmallTicks: false,
          }}
          renderingTicks={renderingTicksHandler.bind(this) as any}
        />
        <span className="sliderwrap__limit-tick" id={`slider${id}__limit-tick`}>
          {showLimitValue && formatedText}
        </span>
      </div>
      {renderSpecials()}
    </div>
  );
};
