import { call, put, select, takeEvery } from 'typed-redux-saga';
import { apiClient } from '../server/api-client';
import { dropInvalidOptionalValues, getSliderLimit } from '../utils';
import { validateSlider } from '../validators/slider-validator';
import { inputsSelector, limitsSelector, programSelector, valuesSelector } from './selectors';

import {
  CALCULATE_LOAN,
  FETCH_LIMITED_INPUTS_END,
  CALCULATE_LOAN_ERROR,
  CALCULATE_LOAN_START,
  UPDATE_NO_LIMIT_INPUTS,
} from './types';
import { ValidationState } from './validation-reducer';
//TODO: extract logic and combine with drop...
export function* calculateAsync() {
  try {
    const program: ReturnType<typeof programSelector> = yield select(programSelector);
    const inputs: ReturnType<typeof inputsSelector> = yield select(inputsSelector);
    const { limits }: ReturnType<typeof limitsSelector> = yield select(limitsSelector);
    const values: ReturnType<typeof valuesSelector> = yield select(valuesSelector);

    const validationState = inputs.reduce((result, input) => {
      if (input.uiType === 'CheckBox') {
        const checkboxLimits = limits.find((e) => e.id === input.id)?.availableValues || [];
        const selectedValueId = values.find((e) => e.id === input.id)?.value;
        const selectedValue = checkboxLimits.find((e) => e.id === selectedValueId);
        const isValid = !input.checkLimit || Boolean(selectedValue);
        return { ...result, [input.id]: isValid };
      }
      if (input.uiType === 'Slider') {
        const sliderTrackLimit = getSliderLimit(limits, input.id);

        const availableSpecials = limits
          .find((e) => e.id === input.id)
          ?.availableValues.filter((e) => e.id !== 999);

        const specialValues = input.availableValues
          .filter((e) => e.id !== 999)
          .map((special) => {
            const isDisabled =
              availableSpecials?.find((e) => e.id === special.id)?.isDisabled ?? true;
            return { ...special, isDisabled };
          });

        const sliderFullValue = values.find((e) => e.id === input.id);

        const sliderHasSpecialValue = Boolean(sliderFullValue?.value);
        const specialValueIsAvailable = !specialValues.find((e) => e.id === sliderFullValue?.value)
          ?.isDisabled;

        const specialValueIsValid =
          !input.checkLimit ||
          (sliderHasSpecialValue && specialValueIsAvailable) ||
          !sliderHasSpecialValue;

        const sliderValueIsValid =
          !input.checkLimit || validateSlider(sliderTrackLimit, sliderFullValue?.sliderValue);

        const isValid =
          (sliderHasSpecialValue && specialValueIsValid) ||
          (!sliderHasSpecialValue && sliderValueIsValid);

        return { ...result, [input.id]: isValid };
      }
      if (input.uiType === 'SelectList') {
        const selectLimits = limits.find((e) => e.id === input.id)?.availableValues || [];

        const options = input.availableValues.map((value) => {
          const limit = selectLimits.find((e) => e.id === value.id);
          return {
            ...value,
            isDisabled: limit ? limit.isDisabled : true,
          };
        }); 

        const selectedValueId = values.find((e) => e.id === input.id)?.value;
        const selectedValue = options.find((e) => e.id === selectedValueId);  
        const isValid = options.every(el => el.isDisabled) || !selectedValue?.isDisabled;
        return { ...result, [input.id]: isValid };
      }

      return result;
    }, {} as ValidationState);

    yield put({ type: 'SET_IS_VALID_BATCH', payload: validationState });
    yield put({ type: CALCULATE_LOAN_START });
    if (Object.values(validationState).every((isValid) => isValid)) {
      const body = [
        ...dropInvalidOptionalValues(inputs, limits, values),
        { id: 0, value: program.value ? parseInt(program.value) : program.defaultValue },
      ];
      const response = yield call(apiClient.loans.calculateCreate as any, body);
      if (response.data.success) {
        yield put({ type: CALCULATE_LOAN, payload: response.data.data });
      } else {
        yield put({ type: CALCULATE_LOAN_ERROR });
      }
    }
  } catch (error) {
    yield put({ type: CALCULATE_LOAN_ERROR });
  }
}
export function* watchLimitsEnd() {
  yield takeEvery([FETCH_LIMITED_INPUTS_END, UPDATE_NO_LIMIT_INPUTS], calculateAsync);
}
