export const FETCH_PROGRAMS = 'PROGRAMS/FETCH_PROGRAMS';
export const UPDATE_PROGRAMS = 'PROGRAMS/UPDATE_PROGRAMS';
export const FETCH_INPUTS = 'INPUTS/FETCH_INPUTS';
export const FETCH_LIMITED_INPUTS_START = 'INPUTS/FETCH_LIMITED_INPUTS_START';
export const FETCH_LIMITED_INPUTS_END = 'INPUTS/FETCH_LIMITED_INPUTS_END';
export const UPDATE_INPUTS = 'INPUTS/UPDATE_INPUTS';
export const UPDATE_NO_LIMIT_INPUTS = 'INPUTS/UPDATE_NO_LIMIT_INPUTS';
export const CALCULATE_LOAN = 'LOAN/CALCULATE_LOAN';
export const CALCULATE_LOAN_START = 'LOAN/CALCULATE_LOAN_START';
export const CALCULATE_LOAN_ERROR = 'LOAN/CALCULATE_LOAN_ERROR';
