import * as React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
export default function QuickPricerHeader(params) {
  const showBanner = false;

  return (
    <div className="container">
      <nav className="quickPricerHeader__menu">
        <ul>
          <li className=""><h1>Quick Pricer</h1></li>
        </ul>
      </nav>
     {showBanner && <div className="quickPricerHeader__msg"><CheckCircleOutlineIcon sx={{mr: 1}}/> Second Lien is now available! See "Program" section.</div>}
    </div>
  );
}
