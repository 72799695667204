import { FilterModel } from '../server/schema/api';
import { FETCH_LIMITED_INPUTS_END, FETCH_LIMITED_INPUTS_START } from './types';

export type LimitsState = {
  state: 'NONE' | 'PENDING' | 'DONE';
  limits: FilterModel[];
};
const initialState: LimitsState = {
  state: 'NONE',
  limits: [],
};

export const limitsReducer = (state = initialState, action): LimitsState => {
  switch (action.type) {
    case FETCH_LIMITED_INPUTS_START:
      return { ...state, state: 'PENDING' };
    case FETCH_LIMITED_INPUTS_END:
      return { state: 'DONE', limits: action.payload as FilterModel[] };
    default:
      return state;
  }
};
