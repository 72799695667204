import { FilterModel } from '../server/schema/api';
import { FETCH_PROGRAMS, UPDATE_PROGRAMS } from './types';

export type ProgramState = FilterModel;
const initialState: ProgramState = {} as any;

export const programReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROGRAMS:
      return action.payload;
    case UPDATE_PROGRAMS:
      return action.payload;
    default:
      return state;
  }
};
