import { FilterModel } from '../server/schema/api';
import { FETCH_INPUTS, UPDATE_INPUTS, UPDATE_NO_LIMIT_INPUTS } from './types';

export type ValuesState = { id: number; value?: number; sliderValue?: number }[];

const initialState: ValuesState = [];

const convert = (payload: FilterModel[]): ValuesState => {
  return payload.map((input) => {
    return input.uiType === 'Slider'
      ? {
          id: input.id,
          sliderValue: input.defaultValue,
        }
      : { id: input.id, value: input.defaultValue };
  });
};

export const valuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INPUTS:
      return convert(action.payload);
    case UPDATE_INPUTS:
      return [...state.filter((e) => e.id !== action.payload.id), action.payload];
    case UPDATE_NO_LIMIT_INPUTS:
      return [...state.filter((e) => e.id !== action.payload.id), action.payload];
    default:
      return state;
  }
};
