import { Dispatch } from 'redux';
import { apiClient } from '../server/api-client';
import { HttpResponse, RequestFilterContract, ResponseModel1 } from '../server/schema/api';
import {
  CALCULATE_LOAN_ERROR,
  FETCH_INPUTS,
  FETCH_LIMITED_INPUTS_END,
  FETCH_LIMITED_INPUTS_START,
  FETCH_PROGRAMS,
  UPDATE_INPUTS, UPDATE_NO_LIMIT_INPUTS,
  UPDATE_PROGRAMS,
} from './types';

export function fetchPrograms() {
  return async (dispatch: Dispatch) => {
    const response = await apiClient.programs.programsList();
    const result = response.data;
    dispatch({ type: FETCH_PROGRAMS, payload: result.data });
    const id = result.data?.defaultValue || 0;
    await fetchInputs(id)(dispatch);
  };
}

export function updateInputs(input: any) {
  return {
    type: UPDATE_INPUTS,
    payload: input,
  };
}
export function updateNoLimitInputs(input: any) {
  return {
    type: UPDATE_NO_LIMIT_INPUTS,
    payload: input,
  };
}

export function updatePrograms(program: any) {
  return {
    type: UPDATE_PROGRAMS,
    payload: program,
  };
}

export function fetchInputs(programId: number) {
  return async (dispatch: Dispatch) => {
    const response = await apiClient.programs.programsDetail(programId);
    const result = response.data;
    dispatch({ type: FETCH_INPUTS, payload: result.data?.filter((i) => i.order !== 99) });
  };
}

export function fetchLimitedInputs(inputs: RequestFilterContract[], programId: number) {
  const data = inputs.map((e) => {
    if (e.value && e.sliderValue) {
      return { id: e.id, value: e.value };
    }
    return e;
  });
  let jsonBody = programId ? [...data, { id: 0, value: programId }] : data;
  jsonBody = jsonBody.map((i: any) => {
    return { id: i.id, value: i.value, sliderValue: i.sliderValue };
  });
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FETCH_LIMITED_INPUTS_START });
      const response = await apiClient.limits.limitsCreate(jsonBody);
      const json = response.data;
      if (json.success === true) {
        dispatch({ type: FETCH_LIMITED_INPUTS_END, payload: json.data });
      }
    } catch (e) {
      const programNotFound = (e as HttpResponse<any, ResponseModel1>).error.errors?.some(
        (e) => e.code === 111,
      );
      if (programNotFound) {
        dispatch({ type: CALCULATE_LOAN_ERROR });
      }
    }
  };
}
