import { combineReducers } from 'redux';
import { inputsReducer, InputsState } from './inputsReducer';
import { limitsReducer, LimitsState } from './limits-reducer';
import { loansReducer, LoansState } from './loans-reducer';
import { programReducer, ProgramState } from './program-reducer';
import { validationReducer, ValidationState } from './validation-reducer';
import { valuesReducer, ValuesState } from './valuesReducer';

export type AppState = {
  program: ProgramState;
  inputs: InputsState;
  limits: LimitsState;
  loans: LoansState;
  validation: ValidationState;
  values: ValuesState;
};

export const rootReducer = combineReducers<AppState>({
  program: programReducer,
  inputs: inputsReducer,
  limits: limitsReducer,
  loans: loansReducer,
  validation: validationReducer,
  values: valuesReducer,
});
