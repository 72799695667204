import { useSelector } from 'react-redux';
import { configInputsSelector } from '../redux/selectors';
import { useSlider } from '../hooks/use-slider';
import { useSelect } from '../hooks/use-select';
import { useCheckbox } from '../hooks/use-checkbox';
export const QPConfig = () => {
  const configInputs = useSelector(configInputsSelector);
  const renderSlider = useSlider();
  const renderSelect = useSelect();
  const renderCheckbox = useCheckbox();

  return (
    <div className="quickPricerBody__config">
      {configInputs.map((input) => {
        switch (input.uiType) {
          case 'SelectList':
            return renderSelect(input);
          case 'Slider':
            return renderSlider(input);
          case 'CheckBox':
            return renderCheckbox(input);
          default:
            break;
        }
        return null;
      })}
    </div>
  );
};
