import '../../scss/quickPricer.scss';

import React, { useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QPHeader from '../../components/QPHeader';
import QPSelects from '../../components/QPSelects';
import QPBody from '../../components/QPBody';
import { fetchLimitedInputs, fetchPrograms } from '../../redux/actions';
import { isEqual } from 'lodash';
import {
  inputsSelector,
  limitsStateSelector,
  limitsValueSelector,
  programSelector,
  valuesSelector,
} from '../../redux/selectors';
import styles from './quickPricer.module.css';
function QuickPricer(params) {
  const dispatch = useDispatch();
  const limits = useSelector(limitsValueSelector);
  const state = useSelector(limitsStateSelector);
  const inputs = useSelector(inputsSelector);
  const program = useSelector(programSelector);
  const values = useSelector(valuesSelector);
  let refLimits = useRef();
  let refNewValues = useRef();
  useEffect(() => dispatch(fetchPrograms()), [dispatch]);
  useEffect(() => {
    if (inputs.length === 0) return;
    //  if (isEqual(limits, refLimits.current)) return;
    const newValues = [];
    values.forEach((i, index) => {
      let elInInputs = inputs.find((e) => e.id === i.id);
      const cond = limits.find((e) => e.id === i.value)?.availableValues.find((e) => i.id === e.id);

      if (elInInputs.checkLimit || cond) {
        newValues.push(i);
      }
      refLimits.current = limits;
    });
    if (
      isEqual(
        refNewValues.current?.sort((a, b) => a.id - b.id),
        newValues.sort((a, b) => a.id - b.id),
      )
    )
      return;
    refNewValues.current = newValues;
    dispatch(
      fetchLimitedInputs(newValues, program.value ? parseInt(program.value) : program.defaultValue),
    );
  }, [dispatch, program, inputs, values, limits, state]);

  const isLoading = limits.length === 0;

  return (
    <div className="quickPricer">
      <div className="quickPricerHeader">
        <QPHeader />
      </div>
      {isLoading ? (
        <div className={styles.spinnerWrapper}>
          <CircularProgress style={{ color: '#00a2ae' }} className={styles.spinner} />
        </div>
      ) : (
        <>
          {<QPSelects />}
          <QPBody />
        </>
      )}
    </div>
  );
}

export default QuickPricer;
