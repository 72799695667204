import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { BaseFilterModel } from '../../server/schema/api';

type Props = {
  id: number;
  order: number;
  selectedValue?: BaseFilterModel;
  name: string;
  isValid: boolean;
  values?: BaseFilterModel[];
  onChange?: (value: BaseFilterModel) => void;
};

export const QPMuiSelect: React.FC<Props> = ({
  isValid,
  order,
  name,
  selectedValue,
  onChange,
  values = []
}) => {
  function handleDataChange(e) {
    const valueID = parseInt(e.target.value);
    const value = values.find((e) => e.id === valueID);
    onChange?.(value!);
  }


  return (
    <FormControl error={!isValid} sx={{ mt: 2}} className='select-wrapper' id={`select-wrapper-${name}`} style={name==="Program"?{order, color: "#fff", backgroundColor: "#263B5A" }:{ order }}>
    <InputLabel style={name==="Program"?{color: "#fff"}:{}} variant="filled" id="demo-simple-select-label">{name}</InputLabel>
    <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        style={name==="Program"?{color: "#fff"}:{}}
        variant="filled"
        value={selectedValue?.id}
        label={name}
        disableUnderline={true}
        onChange={handleDataChange}
    >
    {values.map((item) => (
         <MenuItem disabled={item.isDisabled} value={item.id}>{item.value}</MenuItem>
    ))}
  </Select>
</FormControl>
  );
};
