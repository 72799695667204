/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BaseFilterModel {
  /** @format int32 */
  id: number;
  value: string;

  /** @format int32 */
  order: number;

  /** @format double */
  from?: number;

  /** @format double */
  to?: number ;

  /** @format double */
  sliderValue?: number;
  isDisabled: boolean;
}

/**
 * @format int32
 */
export type ErrorCodes =
  | 100
  | 101
  | 102
  | 103
  | 104
  | 110
  | 111
  | 150
  | 151
  | 160
  | 161
  | 200
  | 201
  | 202;

export interface FilterListResponse {
  data?: FilterModel[] | null;
  errors?: QpError[] | null;
  success?: boolean;
}

export interface FilterModel {
  /** @format int32 */
  id: number;
  value: string;

  /** @format int32 */
  order: number;

  /** @format double */
  from?: number | null;

  /** @format double */
  to?: number | null;

  /** @format double */
  sliderValue?: number | null;
  isDisabled?: boolean | null;
  availableValues: BaseFilterModel[];
  uiType: UIControlTypes;
  name: string;

  /** @format int32 */
  defaultValue: number;
  checkLimit?: boolean;

  /** @format float */
  sliderStep?: number;
}

export interface FilterResponse {
  data?: FilterModel;
  errors?: QpError[] | null;
  success?: boolean;
}

export interface LoanResponse {
  data?: LoanResponseModel;
  errors?: QpError[] | null;
  success?: boolean;
}

export interface LoanResponseModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  currentVariant?: number;
  variants:  {
    id: number;
    rate: string;
    discount: string;
    credit: string;
    monthlyPayment: string;
  }[]
}

export interface QpError {
  code?: ErrorCodes;
  message?: string | null;
}

export interface RequestFilterContract {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  value?: number;

  /** @format double */
  sliderValue?: number;
}

export interface ResponseModel1 {
  data?: T;
  errors?: QpError[] | null;
  success?: boolean;
}

export type T = object;

/**
 * @format int32
 */
export type UIControlTypes = 'SelectList' | 'Slider' | 'CheckBox';

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title QuickPricer Web Api
 * @version version: 1.121.1110.2001
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  limits = {
    /**
     * No description
     *
     * @tags Limits
     * @name LimitsCreate
     * @summary Returns a collection of filters with available values, limited by the selected filters.
     * @request POST:/Limits
     * @response `200` `FilterListResponse` Success
     * @response `400` `ResponseModel1` Bad Request
     */
    limitsCreate: (data: RequestFilterContract[], params: RequestParams = {}) =>
      this.request<FilterListResponse, ResponseModel1>({
        path: `/Limits`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  loans = {
    /**
     * No description
     *
     * @tags Loans
     * @name CalculateCreate
     * @summary Calculate loan by selected filters.
     * @request POST:/Loans/Calculate
     * @response `200` `LoanResponse` Success
     * @response `400` `ResponseModel1` Bad Request
     */
    calculateCreate: (data: RequestFilterContract[], params: RequestParams = {}) =>
      this.request<LoanResponse, ResponseModel1>({
        path: `/Loans/Calculate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  programs = {
    /**
     * No description
     *
     * @tags Programs
     * @name ProgramsList
     * @summary Get all available programs.
     * @request GET:/Programs
     * @response `200` `FilterResponse` Success
     * @response `400` `ResponseModel1` Bad Request
     */
    programsList: (params: RequestParams = {}) =>
      this.request<FilterResponse, ResponseModel1>({
        path: `/Programs`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Programs
     * @name ProgramsDetail
     * @summary Get all filters for a program.
     * @request GET:/Programs/{id}
     * @response `200` `FilterListResponse` Success
     * @response `400` `ResponseModel1` Bad Request
     */
    programsDetail: (id: number, params: RequestParams = {}) =>
      this.request<FilterListResponse, ResponseModel1>({
        path: `/Programs/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
