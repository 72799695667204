import React from 'react';
import { Autocomplete, FormControl, Paper, TextField } from '@mui/material';
import { BaseFilterModel } from '../../server/schema/api';

type Props = {
  id: number;
  order: number;
  selectedValue?: BaseFilterModel;
  name: string;
  isValid: boolean;
  values?: BaseFilterModel[];
  onChange?: (value: BaseFilterModel) => void;
};

export const QPAutocomplete: React.FC<Props> = ({
  isValid,
  order,
  name,
  selectedValue,
  onChange,
  values = []
}) => {
  function handleDataChange(e, value) {
    onChange?.(value);
  }

  return (
    <FormControl error={!isValid} sx={{ mt: 2}} className='select-wrapper' id={`select-wrapper-${name}`} style={name==="Program"?{order, color: "#fff", backgroundColor: "#263B5A" }:{ order }}>
        <Autocomplete
        disablePortal
        id="combo-box-demo"
        getOptionLabel={(option) => option.value }
        value={selectedValue}
        options={values}
        getOptionDisabled={(option) => option.isDisabled }      
        onChange={handleDataChange}
        PaperComponent={({ children }) => (
          <Paper>{children}</Paper>
        )}
        ListboxProps={{
          className: "qpAutocompleteList",
          style:{
            maxHeight: '225px',
        }
        }}
        renderInput={(params) => <TextField error={!isValid} variant='filled' className='qpAutocompleteTextField'sx={{"& fieldset": { border: 'none' }}} {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} label={name} />}
        />
   </FormControl>
  );
};
