import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuickPricerSlider } from '../components/inputs/QPSlider';
import { updateInputs } from '../redux/actions';
import { limitsSelector, valuesSelector } from '../redux/selectors';
import { FilterModel } from '../server/schema/api';
import { getSliderLimit } from '../utils';
import { validateSlider } from '../validators/slider-validator';

export const useSlider = () => {
  const dispatch = useDispatch();
  const { limits } = useSelector(limitsSelector);
  const values = useSelector(valuesSelector);

  return useCallback(
    (slider: FilterModel) => {
      const sliderTrackLimit = getSliderLimit(limits, slider.id);
      const availableSpecials = limits
        .find((e) => e.id === slider.id)
        ?.availableValues.filter((e) => e.id !== 999);

      const specialValues = slider.availableValues
        .filter((e) => e.id !== 999)
        .map((special) => {
          const isDisabled =
            availableSpecials?.find((e) => e.id === special.id)?.isDisabled ?? true;
          return { ...special, isDisabled };
        });

      const sliderFullValue = values.find((e) => e.id === slider.id);

      const sliderHasSpecialValue = Boolean(sliderFullValue?.value);

      const sliderValueIsValid =
        !slider.checkLimit || validateSlider(sliderTrackLimit, sliderFullValue?.sliderValue);
      const isValid = sliderValueIsValid || sliderHasSpecialValue;

      const handleChange = (sliderValue: number) => {
        const isValid = !slider.checkLimit || validateSlider(sliderTrackLimit, sliderValue);
        dispatch({ type: 'SET_IS_VALID', payload: { [slider.id]: isValid } });
        dispatch(updateInputs({ id: slider.id, sliderValue: sliderValue }));
      };

      const handleSpecialClick = ({ type, special }) => {
        dispatch(
          updateInputs({
            id: slider.id,
            sliderValue: sliderFullValue?.sliderValue,
            value: type === 'check' ? special.id : undefined,
          }),
        );
      };

      return (
        <QuickPricerSlider
          key={slider.id}
          id={slider.id}
          order={slider.order}
          min={slider.from!}
          max={slider.to!}
          step={slider.sliderStep}
          limitFrom={sliderTrackLimit?.from ?? undefined}
          limitTo={sliderTrackLimit?.to ?? undefined}
          fullValue={sliderFullValue}
          isValid={isValid}
          label={slider.name}
          onChange={handleChange}
          onSpecialClick={handleSpecialClick}
          specialValues={specialValues}
        />
      );
    },
    [dispatch, values, limits],
  );
};
