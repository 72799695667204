import { AppState } from './rootReducer';

export const programSelector = (state: AppState) => state.program;
export const limitsSelector = (state: AppState) => state.limits;
export const limitsValueSelector = (state: AppState) => state.limits.limits;
export const inputsSelector = (state: AppState) => state.inputs;
export const loansSelector = (state: AppState) => state.loans.loan;
export const loansSuccessSelector = (state: AppState) => state.loans.success;
export const loansStateSelector = (state: AppState) => state.loans.state;
export const limitsStateSelector = (state: AppState) => state.limits.state;
export const validationSelector = (state: AppState) => state.validation;
export const selectedProgramSelector = (state: AppState) =>
  state.program.value || state.program.defaultValue;
export const configInputsSelector = (state: AppState) =>
  inputsSelector(state)
    .sort((a, b) => a.order - b.order)
    .filter((item, index) => index >= 4 && item.order !== 99);

export const headerInputsSelector = (state: AppState) =>
  inputsSelector(state)
    .sort((a, b) => {
      return a.order - b.order;
    })
    .filter((item, index) => index < 4 && item.order !== 99);

export const valuesSelector = (state: AppState) => state.values;
// ((item) => item.order < 4 && item.order !== 99)
