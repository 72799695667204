import { FilterModel } from '../server/schema/api';
import { FETCH_INPUTS } from './types';

export type InputsState = FilterModel[];

const initialState: InputsState = [];

export const inputsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INPUTS:
      return action.payload;
    default:
      return state;
  }
};
