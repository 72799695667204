import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QPSwitch } from '../components/inputs/QPSwitch';
import { updateInputs } from '../redux/actions';
import { limitsSelector, valuesSelector } from '../redux/selectors';
import { BaseFilterModel, FilterModel } from '../server/schema/api';

export const useCheckbox = () => {
  const dispatch = useDispatch();
  const { limits } = useSelector(limitsSelector);
  const values = useSelector(valuesSelector);
  return useCallback(
    (checkbox: FilterModel) => {
      const checkboxLimits = limits.find((e) => e.id === checkbox.id)?.availableValues || [];

      const options = checkbox.availableValues.map((value) => {
        const limit = checkboxLimits.find((e) => e.id === value.id);
        return {
          ...value,
          isDisabled: limit ? limit.isDisabled : true,
        };
      });

      const selectedValueId = values.find((e) => e.id === checkbox.id)?.value;
      const selectedValue = options.find((e) => e.id === selectedValueId);
      const isValid = !checkbox.checkLimit || Boolean(!selectedValue?.isDisabled);

      const handleChange = (value: BaseFilterModel) => {
        const isValid = true;
        dispatch({ type: 'SET_IS_VALID', payload: { [checkbox.id]: isValid } });
        dispatch(updateInputs({ id: checkbox.id, value: value?.id }));
      };

      const isVisible =
        checkbox.checkLimit || (!checkbox.checkLimit && options.some((e) => !e.isDisabled));

      return isVisible ? (
        <QPSwitch
          selectedValue={selectedValue}
          name={checkbox.name}
          key={checkbox.id}
          order={checkbox.order}
          isValid={isValid}
          values={options}
          onChange={handleChange}
        />
      ) : null;
    },
    [dispatch, limits, values],
  );
};
