import React from 'react';
import { QPConfig } from './QPConfig';
import QPFooter from './QPFooter';
import QPResponse from './QPResponse';
export default function QPBody() {
  return (
    <div className="quickPricerBody">
      <div className="quickPricerBody__container_vertical container">
        <div className="quickPricerBody__container container">
          <QPConfig />
          <QPResponse />
        </div>
        <QPFooter />
      </div>
    </div>
  );
}
