import { LoanResponseModel } from '../server/schema/api';
import { CALCULATE_LOAN, CALCULATE_LOAN_ERROR, CALCULATE_LOAN_START } from './types';

export type LoansState = {
  loan?: LoanResponseModel;
  success: boolean;
  state: any;
};
const initialState: LoansState = {
  success: true,
  state: 'NONE'
};

export const loansReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALCULATE_LOAN:
      return {success: true, loan: action.payload, state: 'DONE'};
      case CALCULATE_LOAN_ERROR:
      return { success: false, state: 'DONE'}
      case CALCULATE_LOAN_START:
      return { success: false, state: 'PENDING'}
    default:
      return state;
  }
};
