import { useRef, useEffect, MutableRefObject } from "react";

export const useVariantsRef = (onIncrement, onDecrement): MutableRefObject<null> => {
    const ref = useRef(null);
    useEffect(() => {
    if (!ref.current) {
      return;
    }
    const container = (ref.current as any).container as HTMLDivElement;
    const spinUp = container.querySelector('.e-spin-up');
    const incrementButton = spinUp?.cloneNode(true);
    if (incrementButton) {
      spinUp?.parentNode?.replaceChild(incrementButton, spinUp);
      incrementButton?.addEventListener('click', onIncrement);
    }
    const spinDown = container.querySelector('.e-spin-down');
    const decrementButton = spinDown?.cloneNode(true);
    if (decrementButton) {
      spinDown?.parentNode?.replaceChild(decrementButton, spinDown);
      decrementButton?.addEventListener('click', onDecrement);
    }

    return () => {
      incrementButton?.removeEventListener('click', onIncrement);
      decrementButton?.removeEventListener('click', () => onDecrement);
    }

  });
  return ref;
}