import React, { useEffect } from 'react';
import { FormControl, InputLabel, ListSubheader, ListSubheaderProps, MenuItem, Select } from '@mui/material';
import { BaseFilterModel } from '../../server/schema/api';

type Props = {
  id: number;
  order: number;
  selectedValue?: BaseFilterModel;
  name: string;
  isValid: boolean;
  values?: BaseFilterModel[];
  onChange?: (value: BaseFilterModel) => void;
};

function MyListSubheader(props: ListSubheaderProps) {
  return <ListSubheader sx={{fontWeight: 'bold', fontSize: 14, bgcolor: '#f1f2f3'}} {...props} />;
}

MyListSubheader.muiSkipListHighlight = true;
export default MyListSubheader;

export const QPMuiProgramSelect: React.FC<Props> = ({
  isValid,
  order,
  name,
  selectedValue,
  onChange,
  values = []
}) => {
  const [secondLienPrograms, setSecondLienPrograms] = React.useState<BaseFilterModel[]>([]);
  const [firstLienPrograms, setFirstLienPrograms] = React.useState<BaseFilterModel[]>([...values]);
  useEffect(() => {
    const programs = [...values]
    const secondLienProgramIndex = programs.findIndex(program => program.value === 'Non-QM/Second Lien');
    setSecondLienPrograms([programs[secondLienProgramIndex]]);
    programs.splice(secondLienProgramIndex, 1)
    setFirstLienPrograms(programs);
  }, [values]);

  function handleDataChange(e) {
    const valueID = parseInt(e.target.value);
    const value = values.find((e) => e.id === valueID);
    onChange?.(value!);
  }


  return (
    <FormControl error={!isValid} sx={{ mt: 2}} className='select-wrapper' id={`select-wrapper-${name}`} style={name==="Program"?{order, color: "#fff", backgroundColor: "#263B5A" }:{ order }}>
    <InputLabel style={name==="Program"?{color: "#fff"}:{}} variant="filled" id="demo-simple-select-label">{name}</InputLabel>
    <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        style={name==="Program"?{color: "#fff"}:{}}
        variant="filled"
        value={selectedValue?.id}
        label={name}
        disableUnderline={true}
        onChange={handleDataChange}
    >
    <MyListSubheader>First Lien</MyListSubheader>
    {firstLienPrograms.map((item) => (
         <MenuItem disabled={item.isDisabled} value={item.id}>{item.value}</MenuItem>
    ))}
    <MyListSubheader>Second Lien</MyListSubheader>
    {secondLienPrograms?.map((item) => (
         <MenuItem disabled={item?.isDisabled} value={item?.id}>{item?.value}</MenuItem>
    ))}
  </Select>
</FormControl>
  );
};
