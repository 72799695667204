export type ValidationState = { [id: number]: boolean };
const initialState: ValidationState = {};

export const validationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_VALID':
      return { ...state, ...action.payload };
    case 'SET_IS_VALID_BATCH':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
